import React, { useEffect, useState } from 'react';
import { retrieveLaunchParams } from '@telegram-apps/sdk';
import { useParams, useNavigate } from 'react-router-dom';
import { StreamVideoClient } from '@stream-io/video-react-sdk';

function CreateRoom({client}) {
  // 텔레그램에서 사용자 정보 가져오기
  const { initDataRaw, initData } = retrieveLaunchParams();
  
  const [selectedColor, setSelectedColor] = useState('#4B2178');
  const [roomName, setRoomName] = useState('');
  const [error, setError] = useState(''); // 오류 메시지 상태 관리
  const [selectedTopic, setSelectedTopic] = useState(''); // 선택된 토픽 상태 관리

  const navigate = useNavigate();

  const colors = [
    '#FFFFFF', '#FF3B30', '#FF9500', '#FFCC00', '#34C759', '#30D158', '#5AC8FA', '#007AFF', '#5856D6', '#AF52DE', '#FF2D55', '#000000'
  ];

  // 방 생성 함수
  const createRoom = async () => {
    try {
      // 텔레그램 사용자 ID 사용
      const hostUserId = initData?.user?.id;
      if (!hostUserId) {
        setError('사용자 정보를 가져오는 데 실패했습니다. 텔레그램을 통해 다시 접속해 주세요.');
        return;
      }

      let name = initData.user.firstName;
      if (initData.user.lastName) {
        name += ` ${initData.user.lastName}`;
      }

      const response = await fetch('https://api.telestage.qwe123.win/create-room', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'authorization': `Bearer ${localStorage.getItem('jwtToken')}` // JWT 토큰 사용
        },
        body: JSON.stringify({
          name: roomName,
          topic: selectedTopic || 'General',  // 선택된 주제 사용
          color: selectedColor,
          hostName: name, // 호스트 사용자 이름을 포함
        }),
      });

      const result = await response.json();
      if (result.success) {
        navigate(`/room/${result.roomId}`);
      } else {
        setError('방 생성에 실패했습니다. 다시 시도해 주세요.');
      }
    } catch (error) {
      console.error('Error creating room:', error);
      setError('방 생성 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="bg-[#080808] min-h-screen text-white font-inter relative">
      {error && (
        <div className="fixed top-0 left-0 cursor-pointer bg-red-500 text-white p-4 text-center mb-4">
          {error}
        </div>
      )}
      {/* TELE STAGE Section */}
      <div className="px-6 py-8">
        <h2 className="text-[16px] font-medium mb-6 text-left">TELE STAGE</h2>
        <h3 className="text-[15px] font-medium mb-4 text-left">Voice Room Name</h3>
        <div className="flex justify-between items-center mb-8">
          <input
            type="text"
            placeholder="Type your voice room name"
            value={roomName}
            onChange={(e) => setRoomName(e.target.value)}
            className="text-[14px] font-regular text-[#808080] bg-transparent border-b border-[#282828] outline-none w-full"
          />
          <span className="text-[#808080] text-[14px] ml-4">{roomName.length}/32</span>
        </div>

        {/* Topic Section */}
        <h3 className="text-[15px] font-medium mb-4 text-left">Topic</h3>
        <div className="flex flex-wrap gap-1 mb-8">
          {['GameFi', 'DeFi', 'Tech', 'TON', 'Event', 'Tax', 'Meme', 'Issue', 'Listing'].map((label, idx) => (
            <button
              key={idx}
              onClick={() => setSelectedTopic(label)}
              className={`border border-[#282828] rounded-full px-4 py-2 ${selectedTopic === label ? 'bg-white text-[#080808]' : 'bg-transparent text-[#808080] hover:bg-[#282828] hover:text-white'}`}
            >
              {label}
            </button>
          ))}
        </div>

        {/* Color Section */}
        <h3 className="text-[15px] font-medium mb-4 text-left">Color</h3>
        <div className="flex flex-wrap gap-4 mb-8">
          {colors.map((color, idx) => (
            <div
              key={idx}
              className={`w-[30px] h-[30px] rounded-full cursor-pointer border-4 ${selectedColor === color ? 'border-[#007AFF]' : 'border-transparent'}`}
              style={{ backgroundColor: color }}
              onClick={() => setSelectedColor(color)}
            />
          ))}
        </div>

        {/* Preview Section */}
        <h3 className="text-[15px] font-medium mb-4 text-left">Preview</h3>
        <div className="bg-[#4B2178] rounded-lg p-6 text-white mb-8" style={{ backgroundColor: selectedColor }}>
          <h3 className="text-[36px] font-regular text-left">{roomName || 'Lorem ipsum dolor sit'}</h3>
          <p className="text-[12px] font-regular text-[#FFFFFF] mt-2 text-left">Quis hendrerit</p>

          <div className="flex justify-between items-center mt-6">
            <div className="relative flex items-center">
              <span className="w-[36.69px] h-[36.69px] bg-[#D9D9D9] rounded-full"></span>
              <span className="w-[36.69px] h-[36.69px] bg-[#A6A6A6] rounded-full -ml-[18.34px]"></span>
              <span className="w-[36.69px] h-[36.69px] bg-[#808080] rounded-full -ml-[18.34px]"></span>
              <span className="w-[36.69px] h-[36.69px] bg-[#333333] rounded-full -ml-[18.34px] flex items-center justify-center text-white text-[8px] font-medium">
                +99M
              </span>
            </div>
            <button className="bg-black text-white px-8 py-2 rounded-full">JOIN</button>
          </div>
        </div>
      </div>

      {/* Bottom Button */}
      <button onClick={createRoom} className="fixed bottom-0 left-0 w-full bg-[#007AFF] text-white text-[15px] font-semibold text-center py-4 hover:bg-[#0056B3] cursor-pointer">
        Create Room
      </button>
    </div>
  );
}

export default CreateRoom;