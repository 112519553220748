import { useCall, useCallStateHooks } from '@stream-io/video-react-sdk';

export const MyControlsPanel = () => {
  const { useMicrophoneState, useSpeakerState } = useCallStateHooks();
  const { microphone, isMute } = useMicrophoneState();
  const { isDeviceSelectionSupported } = useSpeakerState();
  const { speaker, selectedDevice, devices } = useSpeakerState();
  console.log('current mic-id:', selectedDevice);
  console.log('available devices:', devices);

  console.log('is speaker selection supported:', isDeviceSelectionSupported);

  const mic_mute = async () => {
    if (isMute) {
      speaker.setVolume(0.5);
      await microphone.enable();
    } else {
      await microphone.disable();
      speaker.setVolume(0.5);
    }
  };

  return (
    <div className='controls-panel bg-gray-700 text-white p-4 rounded-full flex items-center justify-center w-14 h-14'>
      {/* Mic Control */}
      <button className='mic-button' onClick={mic_mute}> 
        {isMute ? 'Unmute' : 'Mute'}
      </button>
    </div>
  );
};