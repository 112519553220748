import React, { useState, useEffect } from 'react';

const MicrophoneColorChanger = () => {
  const [isGreen, setIsGreen] = useState(false);

  useEffect(() => {
    let audioContext;
    let analyser;
    let microphone;

    const startMicrophone = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        audioContext = new (window.AudioContext || window.webkitAudioContext)();
        analyser = audioContext.createAnalyser();
        microphone = audioContext.createMediaStreamSource(stream);
        microphone.connect(analyser);

        const checkAudioInput = () => {
          const dataArray = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(dataArray);
          const sum = dataArray.reduce((a, b) => a + b, 0);
          const average = sum / dataArray.length;

          setIsGreen(average > 10); // 임계값 설정
          requestAnimationFrame(checkAudioInput);
        };

        checkAudioInput();
      } catch (error) {
        console.error('마이크 접근 에러:', error);
      }
    };

    startMicrophone();

    return () => {
      if (audioContext) {
        audioContext.close();
      }
    };
  }, []);

  return (
    <div className={`w-full h-screen flex items-center justify-center transition-colors duration-300 ${isGreen ? 'bg-green-500' : 'bg-white'}`}>
      <h1 className="text-2xl font-bold">
        {isGreen ? '마이크 입력이 감지되었습니다!' : '마이크에 말씀해 주세요'}
      </h1>
    </div>
  );
};

export default MicrophoneColorChanger;