import './App.css';
import Room from './page/room';
import React, { useState, useEffect } from 'react';
import Home from './page/home';
import CreateRoom from './page/CreateRoom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  StreamVideo,
  StreamCall,
  StreamVideoClient,
} from '@stream-io/video-react-sdk';
import { retrieveLaunchParams } from '@telegram-apps/sdk';
import { jwtDecode } from "jwt-decode";
import MicrophoneColorChanger from './page/MicTest';

// JWT 만료 여부 확인 함수
const isJwtExpired = (token) => {
  try {
    const decoded = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);
    return decoded.exp < currentTime;
  } catch (error) {
    console.error('Error decoding JWT token:', error);
    return true;
  }
};

function App() {
  const [callClient, setCallClient] = useState(null); // JWT 토큰 상태 관리
  const {initDataRaw, initData} = retrieveLaunchParams();
  const [user, setUser] = useState({}); // JWT 토큰 상태 관리
  const apiKey = 'tzz9zph56zs4';

  // 텔레그램에서 사용자 정보 가져오기 및 JWT 발급 및 검증
  useEffect(() => {
    console.log('initData:', initData);
    const storedToken = localStorage.getItem('jwtToken');
     
    let name = initData.user.firstName;
    if (initData.user.lastName) {
      name += ` ${initData.user.lastName}`;
    }
    
    const userData = {
      id: `${initData.user.id}`,
      name: name
    };
    setUser(userData);

    if (storedToken && !isJwtExpired(storedToken) && userData) {
      const token = storedToken;
      const client = new StreamVideoClient({ apiKey, user: userData, token });
      setCallClient(client);
    } else if (initDataRaw && userData) {
      const fetchJwtToken = async () => {
        try {
          const response = await fetch('https://api.telestage.qwe123.win/auth', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              initDataRaw:initDataRaw
            }),
          });

          const result = await response.json();
          if (result.success && result.token) {
            localStorage.setItem('jwtToken', result.token);
            const token = result.token;
            const client = new StreamVideoClient({ apiKey, user: userData, token });
            setCallClient(client);
          }
        } catch (error) {
          console.error('Error fetching JWT token:', error);
        }
      };

      fetchJwtToken();
    }
  }, []);

  return (
    <div className="App">
        <Router>
            <Routes>
                <Route path="/create" element={<CreateRoom client={callClient}/>} />
                <Route path="/room/:roomId" element={<Room client={callClient}/>} />
                <Route path="*" element={<Home client={callClient}/>} />
            </Routes>
        </Router>
    </div>
  );
}

export default App;


// const { useParticipants } = useCallStateHooks();
// const participants = useParticipants();
// const [joinedParticipants, setJoinedParticipants] = useState([]);

// // 새로운 참가자가 들어오면 상태 업데이트
// useEffect(() => {
//   setJoinedParticipants(participants);
// }, [participants]);
