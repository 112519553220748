import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import MyParticipantsPanel from './MyParticipantsPanel';
import {MyControlsPanel} from './MyControlsPanel';
import { useJoinedCall } from '../contexts/JoinedCallProvider';
import {
  Call,
  StreamCall,
  useStreamVideoClient,
} from '@stream-io/video-react-sdk';
import { retrieveLaunchParams } from '@telegram-apps/sdk';

function Room({client}) {
  const {initDataRaw, initData} = retrieveLaunchParams();

  const { setJoinedCall, joinedCall } = useJoinedCall();
  const { roomId } = useParams();
  const [call, setCall] = useState();
  const [roomData, setRoomData] = useState(null);

  const [isRaisedHand, setIsRaisedHand] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const loadRoom = useCallback(async () => {
    if (!(client && roomId)) 
      return;

    try {
      const newCall = client.call('audio_room', roomId);
      let resp = await newCall.get();
      await newCall.join();

      const joinResponse = await fetch(`https://api.telestage.qwe123.win/join-room/${roomId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
        },
      });
      const joinData = await joinResponse.json();
      
      if (!joinResponse.ok || !joinData.success) {
        throw new Error(joinData.message || 'Failed to join the room');
      }
      
      setRoomData(resp.call.custom);
      if (`${initData.user.id}` === resp.call.custom.hostUserId) {
        setIsRaisedHand(true);
      }
      setCall(newCall);
    } catch (e) {
      console.error('Error loading room:', e);
      setError(error.message || 'Failed to get call');
    } finally {
      setIsLoading(false);
    }
  }, [client, roomId]);
  
  useEffect(() => {
    if (roomId && roomId === joinedCall?.id) {
      setCall(joinedCall);
      return;
    }

    loadRoom().catch((e) => {
      console.error('Error loading room', e);
      setError(e.message || 'Failed to load room');
    });
  }, [roomId, loadRoom, joinedCall]);

  const leaveRoom = async () => {
    try {
      const response = await fetch(`https://api.telestage.qwe123.win/leave-room/${roomId}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('jwtToken')}`,
        },
      });
      if (response.ok) {
        await call.leave().catch((err) => {
          console.error('Error leaving call', err);
        });
        setJoinedCall(undefined);
        navigate('/');
      } else {
        setError('Failed to leave the room');
      }
    } catch (error) {
      console.error('Error leaving room:', error);
      setError('An error occurred while leaving the room');
    }
  };

  if (isLoading) {
    return (
      <div className="bg-[#080808] min-h-screen text-white font-inter flex items-center justify-center">
        <p>Loading...</p>
      </div>
    )
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  // if (!roomData || !roomData.participants) {
  //   return <div>No room data available</div>;
  // }

  // console.log('roomData:', roomData);

  // const speakers = roomData.participants.filter(p => p.role === 'Speaker' || p.role === 'Host');
  // const listeners = roomData.participants.filter(p => p.role === 'Listener');

  return (
    <div className="bg-[#080808] min-h-screen text-white font-inter relative">
      <StreamCall call={call}>
        <div className="ui-layout px-6 py-8">
          <h2 className="text-[16px] font-medium mb-6 text-left">TELE STAGE</h2>
          <h3 className="text-[24px] font-medium mb-10 text-left">{roomData.name}</h3>

          {/* Speakers */}
          <MyParticipantsPanel />
  
          {/* Divider */}
          {/* <div className="border-t border-[#464648] my-4 mb-10"></div> */}

          {/* Listeners */}
          {/* <Participants user={listeners} /> */}
        </div>

        {/* Bottom Buttons */}
        <div className='controls-panel'>

        </div>
        
        {isRaisedHand ? (
          <div className="fixed bottom-0 left-0 w-full flex justify-center space-x-10 bg-[#080808] p-4">
            <MyControlsPanel />
            {/* <button className="bg-gray-700 text-white p-4 rounded-full flex items-center justify-center w-14 h-14">
              <svg width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.12096 18.1541C10.6431 18.1541 13.4986 15.1412 13.4986 11.4246V9.57324M7.12096 18.1541C3.5988 18.1541 0.74353 15.1412 0.74353 11.4246V9.57324M7.12096 18.1541V21.9852H3.49164H10.7088" stroke="white" stroke-linecap="round"/>
                <rect x="3.89273" y="0.463623" width="6.42286" height="14.3601" rx="3.21143" fill="white"/>
              </svg>
            </button> */}
            <button onClick={leaveRoom} className="bg-red-500 text-white p-4 rounded-full flex items-center justify-center w-14 h-14">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        ) : (
          <div className="fixed bottom-0 left-0 w-full flex justify-around bg-[#080808] p-4">
            <button
              className="bg-blue-500 text-white px-6 py-2 rounded-[11px] flex items-center"
              onClick={() => setIsRaisedHand(true)}
            >
              Raising Hand ✋
            </button>
            
            <button onClick={leaveRoom} className="bg-orange-500 text-white px-6 py-2 rounded-[11px] flex items-center">
              Leave 🎩
            </button>
          </div>
        )}
      </StreamCall>
    </div>
  );
}

export default Room;