import React, { useState, useEffect } from 'react';
import { Avatar, useCallStateHooks } from '@stream-io/video-react-sdk';

function MyParticipantsPanel() {
  const { useParticipants } = useCallStateHooks();
  const participants = useParticipants();
  const [joinedParticipants, setJoinedParticipants] = useState([]);
  
  // 새로운 참가자가 들어오면 상태 업데이트
  useEffect(() => {
    setJoinedParticipants(participants);
    console.log('participants:', participants);
  }, [participants]);

  return (
    <div className='participants-panel'>
        <div className="grid grid-cols-4 gap-4 mb-10">
        {joinedParticipants.map((participant) => (
            <div key={participant.sessionId} className={`participant ${participant.isSpeaking ? 'speaking' : ''} flex flex-col items-center`}>
                 {/* <Avatar imageSrc={participant.image} /> */}
                <img
                    src={participant.image || "/image5.png"}
                    alt={participant.name}
                    className={`w-[60px] h-[60px] rounded-full ${participant.isSpeaking ? 'border-2 border-green-500' : ""}`}
                />
                <p className="name text-[14px] font-medium mt-2">{participant.name}</p>
                <p className="text-[12px] text-gray-400">{participant.roles[0]}</p>
            </div>
        ))}
        </div>
    </div>
  );
}

export default MyParticipantsPanel;