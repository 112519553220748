import { createContext, PropsWithChildren, useContext, useState } from 'react';

import { Call } from '@stream-io/video-react-sdk';
import { nop } from '../utils/nop';

// type JoinedCallContextValue = {
//   joinedCall?: Call;
//   setJoinedCall: (call?: Call) => void;
// };

const JoinedCallContext = createContext({
  joinedCall: undefined,
  setJoinedCall: nop,
});

export const JoinedCallProvider = ({ children }) => {
  const [joinedCall, setJoinedCall] = useState();

  return (
    <JoinedCallContext.Provider
      value={{
        joinedCall,
        setJoinedCall,
      }}
    >
      {children}
    </JoinedCallContext.Provider>
  );
};

export const useJoinedCall = () => useContext(JoinedCallContext);
