import React, { useState, useEffect } from 'react';
import { useStreamVideoClient } from '@stream-io/video-react-sdk';
import { useParams, useNavigate } from 'react-router-dom';

function Home({client}) {
  const [selectedTopic, setSelectedTopic] = useState('HOT'); // 선택된 토픽 상태 관리
  const [voiceRooms, setVoiceRooms] = useState([]); // 보이스 룸 목록 상태 관리
  const [jwtToken, setJwtToken] = useState(localStorage.getItem('jwtToken')); // JWT 토큰 상태 관리

  const navigate = useNavigate();
  // 보이스 룸 목록 가져오기
  useEffect(() => {
    if (jwtToken && client) {
      const fetchVoiceRooms = async () => {
        try {
          const { calls } = await client.queryCalls({
            filter_conditions: { ongoing: true },
          });

          console.log('calls:', calls);
          
          let rooms = [];
          for (const item of calls) {
            const roomData = await item.get();
            const participants = await item.queryMembers({
              sort: [{ field: 'user_id', direction: 1 }],
              limit: 3,
            });
            rooms.push({
              room_id: item.id,
              name: roomData.call.custom.name,
              color: roomData.call.custom.color,
              topic: roomData.call.custom.topic,
              host_user_name: roomData.call.custom.hostUserName,
              host_user_id: roomData.call.custom.hostUserId,
              host_user_image: roomData.call.custom.hostImage,
              participants: participants.members
            });
          }
          setVoiceRooms(rooms);
        } catch (error) {
          console.error('Error fetching voice rooms:', error);
        }
      };

      fetchVoiceRooms();
    }
  }, [jwtToken, client]);

  return (
    <div className="bg-[#080808] min-h-screen text-white font-inter">
      {/* TELE STAGE Section */}
      <div className="px-6 py-8">
        <h2 className="text-[16px] font-medium text-white mb-6 text-left">TELE STAGE</h2>
        <div className="flex flex-wrap gap-1">
          {/* <button className="bg-white text-[#080808] rounded-full px-4 py-2 hover:bg-gray-200">HOT</button> */}
          {['HOT', 'GameFi', 'DeFi', 'Tech', 'TON', 'Event', 'Tax', 'Meme', 'Issue', 'Listing'].map((label, idx) => (
            <button
              key={idx}
              onClick={() => setSelectedTopic(label)}
              className={`border border-[#282828] rounded-full px-4 py-2 ${selectedTopic === label ? 'bg-white text-[#080808]' : 'bg-transparent text-[#808080] hover:bg-[#282828] hover:text-white'}`}
            >
              {label}
            </button>
          ))}
        </div>
      </div>

      {/* Hot Voice Rooms Section */}
      <div className="px-6">
        <div className='pb-8'>
            <h2 className="text-[16px] font-medium text-white mb-6 text-left">HOT VOICE ROOMS 🔥</h2>
            <a href="/create">
              <div className="bg-transparent border border-[#282828] rounded-lg h-[180px] flex flex-col justify-center items-center pb-8 hover:bg-[#282828]">
                <span className="text-[64px] text-white">+</span>
                <p className="text-[12px] font-regular text-[#808080]">New Voice Room</p>
              </div>
            </a>
        </div>

        {/* Voice Rooms List */}
        {voiceRooms.map((room) => (
          <div key={room.room_id} className="pb-8">
            <div className="rounded-lg p-6 text-white" style={{ backgroundColor: room.color }}>
              <h3 className="text-[36px] font-regular text-left">{room.name}</h3>
              <p className="text-[12px] font-regular text-[#FFFFFF] mt-2 text-left">{room.host_user_name}</p>
              
              {/* Circles and FULL Button */}
              <div className="flex justify-between items-center mt-6">
                  <div className="relative flex items-center">
                      <span className="w-[36.69px] h-[36.69px] bg-[#D9D9D9] rounded-full"></span>
                      <span className="w-[36.69px] h-[36.69px] bg-[#A6A6A6] rounded-full -ml-[18.34px]"></span>
                      <span className="w-[36.69px] h-[36.69px] bg-[#808080] rounded-full -ml-[18.34px]"></span>
                      <span className="w-[36.69px] h-[36.69px] bg-[#333333] rounded-full -ml-[18.34px] flex items-center justify-center text-white text-[10px] font-medium">
                          +{room.participants.length}
                      </span>
                  </div>
                  <button 
                    className="bg-black text-white px-8 py-2 rounded-full hover:bg-[#FFFFFF] hover:text-[#080808]"
                    onClick={() => {
                      navigate(`/room/${room.room_id}`)
                    }}
                  >
                      JOIN
                  </button>

              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Home;